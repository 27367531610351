<template>
  <div class="page-container order">
    <div class="p-20px van-hairline--bottom">Select Payment Method</div>
    <div class="p-20px van-hairline--bottom">
      <van-radio-group v-model="selectedPaymentId" direction="horizontal">
        <van-radio
          v-for="item in paymentListData"
          :key="item.id"
          :name="item.id"
          checked-color="#ffd461"
        >
          <div class="p-4px bg-light-100 rounded-6px">
            <img :src="item.ico_url" class="w-72px h-36px" />
          </div>
        </van-radio>
      </van-radio-group>
    </div>
    <div class="p-20px">
      <van-button block type="primary" text="Pay Now" :loading="loading" @click="onPay" />
    </div>
  </div>
</template>

<script>
  import { loadAirwallex, redirectToCheckout } from 'airwallex-payment-elements';
  import $http from '@/utils/axios';
  import { isIOS } from '@/utils/is';
  import { getParams } from '@/utils/assist';

  export default {
    name: 'Order',
    data() {
      return {
        paymentListData: [
          {
            id: 1,
            name: 'Visa',
            ico_url: 'https://cdn-media.acegame.shop/paymentlogo/visa.svg',
            comment: 'visa信用卡',
            source: 'airwallex',
            type: 'card',
          },
          {
            id: 2,
            name: 'Mastercard',
            ico_url: 'https://cdn-media.acegame.shop/paymentlogo/mastercard.svg',
            comment: '万事达卡',
            source: 'airwallex',
            type: 'card',
          },
          {
            id: 3,
            name: 'PayPal',
            ico_url: 'https://cdn-media.acegame.shop/paymentlogo/paypal.e896de44.svg',
            comment: 'PayPal',
            source: 'paypal',
            type: 'card',
          },
        ],
        selectedPaymentId: 1,
        loading: false,
      };
    },
    methods: {
      async onPay() {
        this.loading = true;
        const urlParams = getParams(
          'price,currency,extend,product_id,product_name,uid,server_id,sdk_uid'
        );
        let isUrlParamsFull = Object.values(urlParams).some((item) => !item);
        if (isUrlParamsFull) {
          this.loading = false;
          this.$toast({
            message: 'The payment address is wrong, please get it again',
            duration: 5000,
          });
          return;
        }
        const data = {
          app_uid: 3745952624152576,
          os_type: isIOS() ? 'ios' : 'android',
          pay_way_id: this.selectedPaymentId,
          ...urlParams,
          price: String(urlParams.price / 100),
        };
        const selectedPayment = this.paymentListData.find(
          (item) => item.id === this.selectedPaymentId
        );
        try {
          const res = await $http.post('/sdk/open_order', data);
          if (res.status !== 'SUCCESS') return;
          this.$toast({
            message: 'Going to jump to a third party payment processor. Please wait...',
            duration: 0,
          });
          try {
            if (res.url && res.type) {
              window.location.href = res.url;
            } else {
              const payEnv = process.env.VUE_APP_PAY_ENV;
              const fallBackUrl = process.env.VUE_APP_PAY_RESULT_URL;
              // Can choose other production environments, 'staging | 'demo' | 'prod'
              await loadAirwallex({ env: payEnv });
              redirectToCheckout({
                env: payEnv,
                intent_id: res.id,
                client_secret: res.client_secret,
                currency: res.currency,
                autoCapture: true,
                components: [selectedPayment.type],
                locale: 'en',
                successUrl: `${fallBackUrl}?amount=${res.amount}&symbol=${res.currency_rel}&order_sn=${res.order_sn}`,
                failUrl: `${fallBackUrl}?amount=${res.amount}&symbol=${res.currency_rel}&order_sn=${res.order_sn}`,
              });
            }
          } finally {
            this.loading = false;
            this.$toast.clear();
          }
        } finally {
          this.loading = false;
        }
      },
    },
    beforeDestroy() {
      this.$toast.clear();
    },
  };
</script>
